
import { defineComponent } from 'vue'
import { useIpStore } from '@/store/ip'

import Circle from 'vue-loading-spinner/src/components/Circle4.vue'

export default defineComponent({
  name: 'IpInfo',

  components: { Circle },

  setup() {
    const ipStore = useIpStore()

    return { ipStore }
  }
})
