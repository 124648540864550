
import { defineComponent } from 'vue'
import AppHeader from '@/components/AppHeader.vue'
import IpInfo from './components/IpInfo.vue'
import Maps from './components/Maps.vue'

export default defineComponent({
  name: 'App',

  components: {
    AppHeader,
    IpInfo,
    Maps
  }
})
